// // src/App.js
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import InvoiceGenerator from './InvoiceGenerator'; // Ensure this path is correct
// // import BulkImport from './Purchase'; // Ensure this path is correct
// import BulkV2 from './BulkV2';
// import Bulkv3 from './Bulkv3';
// import Purchase from './Purchase'

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<InvoiceGenerator />} />
//         {/* <Route path="/bulk-import" element={<BulkImport />} /> */}
//         <Route path="/bulkv2" element={<BulkV2/>}/>
//         <Route path="/bulkv3" element={<Bulkv3/>}/>
//         <Route path="/purchase" element={<Purchase/>}/>


//       </Routes>
//     </Router>
//   );
// };

// export default App; // Ensure this is a default export
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import InvoiceGenerator from './InvoiceGenerator';
import BulkV2 from './BulkV2';
import Bulkv3 from './Bulkv3';
import Purchase from './Purchase';
import Purchaseorder from './Purchaseorder';

const App = () => {
  return (
    <Router>
      <header style={{ padding: '1rem', background: '#f5f5f5', borderBottom: '1px solid #ccc' }}>
        <nav>
          <ul style={{ display: 'flex', gap: '1rem', listStyle: 'none', padding: 0, margin: 0 }}>
            <li><Link to="/Invoice generator">Invoice Generator</Link></li>
            <li><Link to="/">Sequence</Link></li>
            <li><Link to="/Random">Random</Link></li>
            <li><Link to="/purchase">Purchase</Link></li>
            <li><Link to="/purchaseorder">Purchase-order</Link></li>

          </ul>
        </nav>
      </header>
      <main style={{ padding: '1rem' }}>
        <Routes>
          <Route path="/" element={<BulkV2 />} />
          <Route path="/Invoice generator" element={<InvoiceGenerator />} />
          <Route path="/Random" element={<Bulkv3 />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/purchaseorder" element={<Purchaseorder />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
