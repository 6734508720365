import React, { useState, useEffect } from "react";
// import axios from "axios";
import jsPDF from "jspdf";
// import { Link } from 'react-router-dom';
import html2canvas from "html2canvas";
import Papa from 'papaparse'; // Import PapaParse
import * as XLSX from 'xlsx'; // Import XLSX
import "./Style/BulkV2.module.css"; // Make sure to include your CSS file
// import React, { useState, useEffect } from 'react';
import {
    generateInvoiceNumber,
    formatDate,
    numberToWordsInIndianCurrency,
    formatNumberWithCommas,
    // Add other functions as needed
} from './Components/Utils';
const BulkImport = () => {


    const [letter, setletter] = useState();
  
  const [signature, setSignature] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [startingInvoiceNumber, setStartingInvoiceNumber] = useState(1); // State for starting invoice number
  const [generatedInvoiceNumber, setGeneratedInvoiceNumber] = useState("");

  let currentInvoiceNumber = null;

  // Function to save the last invoice number to localStorage
  const saveLastInvoiceNumber = (number) => {
      localStorage.setItem('lastInvoiceNumber', number);
  };

  // Function to get the last saved invoice number from localStorage
  const getLastInvoiceNumber = () => {
      return parseInt(localStorage.getItem('lastInvoiceNumber'), 10) || 1; // Default to 1 if not found
  };

  // Function to set the initial invoice number
  const setInitialInvoiceNumber = (startingNumber) => {
      currentInvoiceNumber = startingNumber;
      // Save the starting number to localStorage
      saveLastInvoiceNumber(currentInvoiceNumber);
  };

  // Function to generate the next invoice number
  const generateInvoiceNumber = () => {
      if (currentInvoiceNumber === null) {
          // Set the initial invoice number if not set
          setInitialInvoiceNumber(startingInvoiceNumber);
      }

      // Format the invoice number as a 4-digit string with leading zeros
      const invoiceNumber = String(currentInvoiceNumber).padStart(4, '0');

      // Increment the invoice number for the next use
      currentInvoiceNumber += 1;

      // Save the new last invoice number to localStorage
      saveLastInvoiceNumber(currentInvoiceNumber);

      return `${letter}-${invoiceNumber}`;
  };

//   let currentInvoiceNumber = null;

// // // Retrieve the last saved invoice number from localStorage or start from 1
// const getLastInvoiceNumber = () => {
//   const savedNumber = localStorage.getItem("lastInvoiceNumber");
//   return savedNumber ? parseInt(savedNumber, 10) : 0; // Start from 1
// };

// // Save the last invoice number to localStorage
// const saveLastInvoiceNumber = (number) => {
//   localStorage.setItem("lastInvoiceNumber", number);
// };

// // Generate the next invoice number, starting from the saved number
// const generateInvoiceNumber = () => {
//   if (currentInvoiceNumber === null) {
//     // Load the last saved invoice number only once per session
//     currentInvoiceNumber = getLastInvoiceNumber();
//   }

//   // Format the invoice number as a 4-digit string with leading zeros
//   const invoiceNumber = String(currentInvoiceNumber).padStart(4, "0");

//   // Increment the invoice number for the next use
//   currentInvoiceNumber += 1;

//   // Save the new last invoice number to localStorage
//   saveLastInvoiceNumber(currentInvoiceNumber);

//   return `INV-${invoiceNumber}`;
// };


const [invoices, setInvoices] = useState([]); // State to hold the list of invoices
const [pdfs, setPdfs] = useState([]); // State to store generated PDFs

const BATCH_SIZE = 50; // Adjust as needed


const [loading, setLoading] = useState(false); // State to track loading


// const generateAndStoreAllInvoices = async () => {
//   console.log("Invoices to generate:", invoices);

//   if (invoices.length === 0) {
//       console.error("No invoices to generate.");
//       return;
//   }

//   // Generate invoice numbers and update the invoices state
//   const updatedInvoices = invoices.map((invoice) => {
//       if (!invoice.currentInvoiceNumber) {
//           invoice.currentInvoiceNumber = generateInvoiceNumber(); // Generate if not present
//       }
//       return invoice; // Return the updated invoice
//   });

//   // Update the state with the new invoices
//   setInvoices(updatedInvoices); // This will trigger a re-render

//   // Set loading state to true
//   setLoading(true);

//   // Generate PDFs for each invoice
//   for (let i = 0; i < updatedInvoices.length; i++) {
//       await generatePDF(updatedInvoices[i], i); // Call generatePDF for each invoice
//   }

//   // Set loading state to false after generation
//   setLoading(false);
// };

const generateAndStoreAllInvoices = async () => {
  console.log("Invoices to generate:", invoices);

  if (invoices.length === 0) {
      console.error("No invoices to generate.");
      return;
  }

  // Generate invoice numbers and update the invoices state
  const updatedInvoices = invoices.map((invoice) => {
      if (!invoice.currentInvoiceNumber) {
          invoice.currentInvoiceNumber = generateInvoiceNumber(); // Generate if not present
          setGeneratedInvoiceNumber(invoice.currentInvoiceNumber); // Store the generated invoice number
      }
      return invoice; // Return the updated invoice
  });

  // Update the state with the new invoices
  setInvoices(updatedInvoices); // This will trigger a re-render

  // Set loading state to true
  setLoading(true);

  // Generate PDFs for each invoice
  for (let i = 0; i < updatedInvoices.length; i++) {
      await generatePDF(updatedInvoices[i], i); // Call generatePDF for each invoice
  }

  // Set loading state to false after generation
  setLoading(false);
};
const generatePDF = async (invoice, index) => {


  if (!invoice || !invoice.invoiceDate) {
    console.error(`Invalid invoice data for index: ${index}`);
    return;
  }

  // Ensure the invoice number exists and format it to "INV-0001" style
  if (!invoice.currentInvoiceNumber) {
    invoice.currentInvoiceNumber = generateInvoiceNumber(); // Generate if not present
  }

  // Format the invoice number to have leading zeros (e.g., "INV-0001")
  const formattedInvoiceNumber = `${invoice.currentInvoiceNumber.toString().padStart(4, "0")}`;

  // Check if the current invoice number matches the generated one
  if (invoice.currentInvoiceNumber === formattedInvoiceNumber) {
    console.log(`Invoice number matches: ${invoice.currentInvoiceNumber}`);
  } else {
    console.error(`Invoice number mismatch: ${invoice.currentInvoiceNumber} !== ${formattedInvoiceNumber}`);
  }
  const pdfContent = `
        Invoice Number: ${invoice.currentInvoiceNumber}
        Date: ${formatDate(invoice.invoiceDate)}
        // Add other invoice details here
    `;
    console.log("Generating PDF for:", pdfContent);

  // Format the date from the uploaded file to YYYY-MM-DD
  const invoiceDate = new Date(invoice.invoiceDate); // Parse the date
  const formattedDate = `${invoiceDate.getFullYear()}-${(invoiceDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${invoiceDate.getDate().toString().padStart(2, "0")}`;

  const sanitizedCompany = invoice.toCompany.replace(/[^a-zA-Z0-9]/g, "_");
  const filename = `${sanitizedCompany}-${formattedDate}-${formattedInvoiceNumber}.pdf`;

  console.log(`Generating PDF with filename: ${filename}`);

  let input = document.getElementById(`invoice-output-${index}`);

  // Retry logic if the DOM element is not immediately available
  for (let attempts = 5; !input && attempts > 0; attempts--) {
    await new Promise((resolve) => setTimeout(resolve, 200)); // Wait 200ms
    input = document.getElementById(`invoice-output-${index}`);
  }

  if (!input) {
    console.error(`Invoice element not found for index: ${index}.`);
    return null;
  }

  try {
    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: false,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.9);
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    // Save the PDF file with the formatted filename
    pdf.save(filename);

    // Log or update the state to confirm the file name matches the invoice number
    console.log(`PDF saved with invoice number: ${formattedInvoiceNumber}`);
    setPdfs((prevPdfs) => [...prevPdfs, filename]); // Add filename to the state
  } catch (error) {
    console.error(`Failed to generate PDF for invoice ${invoice.currentInvoiceNumber}:`, error);
    return null;
  }
};
// const generateInvoice = async (e) => {
//   e.preventDefault();
//   await generateAndStoreAllInvoices();
// };
const generateInvoice = async (e) => {
  e.preventDefault();
  await generateAndStoreAllInvoices();
};
const [showInvoiceNumbers, setShowInvoiceNumbers] = useState(false); // State to toggle invoice number visibility


const toggleInvoiceNumbers = () => {
  setShowInvoiceNumbers(!showInvoiceNumbers); // Toggle the visibility
};



  localStorage.clear();

const downloadPDF = (pdfBlob, index) => {
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `invoice-${index}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL
    };

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;

    if (fileType === 'text/csv') {
        // Handle CSV file
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const allInvoices = results.data.map((row) => {
                    // Basic validation
                    if (!row.invoiceDate || !row.itemNames) {
                        console.error("Missing required fields in CSV row:", row);
                        return null; // Skip this row
                    }

                    const invoiceDate = new Date(row.invoiceDate);
                    const itemNames = row.itemNames.split(',').map(item => item.trim());
                    const itemQuantities = row.itemQuantities ? row.itemQuantities.split(',').map(q => parseInt(q.trim())) : [];
                    const itemPrices = row.itemPrices ? row.itemPrices.split(',').map(p => parseFloat(p.trim())) : [];
                    const itemUnits = row.itemUnits ? row.itemUnits.split(',').map(u => u.trim()) : [];
                    const itemHsn = row.hsn ? row.hsn.split(',').map(h => h.trim()) : [];
                    const itemVariety = row.variety ? row.variety.split(',').map(v => v.trim()) : [];

                    const items = itemNames.map((name, index) => ({
                        itemName: name,
                        quantity: itemQuantities[index] || 0,
                        price: itemPrices[index] || 0,
                        unit: itemUnits[index] || "pcs",
                        hsn: itemHsn[index] || "",
                        variety: itemVariety[index] || ""
                    }));

                    return {
                        fromCompany: row.fromCompany || "",
                        fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
                        toCompany: row.toCompany || "",
                        toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
                        shipToCompany: row.shipToCompany || "",
                        shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
                        bankDetails: {
                            bankName: row.bankName || "",
                            accountNumber: row.accountNumber || "",
                            ifsc: row.ifsc || "",
                            accountHolder: row.accountHolder || ""
                        },
                        invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate,
                        items: items
                    };
                }).filter(invoice => invoice !== null); // Filter out null values

                setInvoices(allInvoices);
            },
            error: (error) => {
                console.error("Error reading CSV file:", error);
                alert("There was an error processing the CSV file. Please check the format and try again.");
            }
        });
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // Handle Excel file
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const allInvoices = jsonData.map((row) => {
                // Basic validation
                if (!row.invoiceDate || !row.itemNames) {
                    console.error("Missing required fields in Excel row:", row);
                    return null; // Skip this row
                }

                const invoiceDate = new Date(row.invoiceDate);
                const itemNames = row.itemNames.split(',').map(item => item.trim());
                const itemQuantities = row.itemQuantities ? row.itemQuantities.split(',').map(q => parseInt(q.trim())) : [];
                const itemPrices = row.itemPrices ? row.itemPrices.split(',').map(p => parseFloat(p.trim())) : [];
                const itemUnits = row.itemUnits ? row.itemUnits.split(',').map(u => u.trim()) : [];
                const itemHsn = row.hsn ? row.hsn.split(',').map(h => h.trim()) : [];
                const itemVariety = row.variety ? row.variety.split(',').map(v => v.trim()) : [];

                const items = itemNames.map((name, index) => ({
                    itemName: name,
                    quantity: itemQuantities[index] || 0,
                    price: itemPrices[index] || 0,
                    unit: itemUnits[index] || "pcs",
                    hsn: itemHsn[index] || "",
                    variety: itemVariety[index] || ""
                }));

                return {
                    fromCompany: row.fromCompany || "",
                    fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
                    toCompany: row.toCompany || "",
                    toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
                    shipToCompany: row.shipToCompany || "",
                    shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
                    bankDetails: {
                        bankName: row.bankName || "",
                        accountNumber: row.accountNumber || "",
                        ifsc: row.ifsc || "",
                        accountHolder: row.accountHolder || ""
                    },
                    invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate,
                    items: items
                };
            }).filter(invoice => invoice !== null); // Filter out null values

            setInvoices(allInvoices);
        };

        reader.readAsBinaryString(file);
    } else {
        alert("Unsupported file type. Please upload a CSV or Excel file.");
    }
};

useEffect(() => {
    if (invoices.length > 0) {
        generatePDF(); // Call PDF generation when invoices are updated
    }
}, [invoices]);
  
  // const [availableItems, setAvailableItems] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceNumber, setInvoiceNumber] = useState(generateInvoiceNumber());
  const [terms, setTerms] = useState("Payment due within 7 days. Late payments may incur an additional fee.");
  const [totalAmount, setTotalAmount] = useState(0);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [shipToSuggestions, setShipToSuggestions] = useState([]);
  const [template, setTemplate] = useState("template1");
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);


  const templates = {
    template1: {
      className: "invoice-preview-template1",
      header: <h2 className="ti"> Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template2: {
      className: "invoice-preview-template2",
      header: <h2>Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template3: {
      className: "invoice-preview-template3",
      // header: <h2>Bill</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
  };
  
 
const formatDate = (date) => {
  if (!date || isNaN(date.getTime())) {
      return "Invalid date"; // Return a placeholder for invalid dates
  }
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};


  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match("image.*")) {
      alert("Please select an image file");
      return;
    }
    if (file.size > 1024 * 1024) {
      alert("Please select an image file with a size less than 1MB");
      return;
    }
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container">
      <h1>Bulk Invoice Generator</h1>
      <form onSubmit={generateInvoice} className="form">
      <div className="form-group">
          <label htmlFor="bulk-import">Bulk Import Items:</label>
          <input
            type="file"
            id="bulk-import"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="letter">Prefix :</label>
          <input
            id="letter"
            value={letter}
            onChange={(e) => setletter(e.target.value)}
            rows="4"
            required
          />
        </div>
        <div className="form-group">
                    <label htmlFor="starting-invoice-number">Starting Invoice Number:</label>
                    <input
                        type="number"
                        id="starting-invoice-number"
                        value={startingInvoiceNumber}
                        onChange={(e) => setStartingInvoiceNumber(Number(e.target.value))}
                        min="1"
                    />
                </div>
        {/* <div className="form-group">
          <label htmlFor="invoice-date">Invoice Date:</label>
          <div className="date-inputs">
            <input
              type="number"
              id="invoice-day"
              placeholder=" DD"
              value={invoiceDate.getDate() || ""}
              onChange={(e) => {
                const day = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setDate(day);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="31"
            />
            <input
              type="number"
              id="invoice-month"
              placeholder="MM"
              value={invoiceDate.getMonth() + 1 || ""}
              onChange ={(e) => {
                const month = parseInt(e.target.value) - 1; // Months are zero-indexed
                const newDate = new Date(invoiceDate);
                newDate.setMonth(month);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="12"
            />
            <input
              type="number"
              id="invoice-year"
              placeholder="YYYY"
              value={invoiceDate.getFullYear() || ""}
              onChange={(e) => {
                const year = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setFullYear(year);
                setInvoiceDate(newDate);
              }}
              required
              min="1900 "
            />
          </div>
        </div> */}
        <div className="form-group">
          <label htmlFor="logo">Logo:</label>
          <input
            type="file"
            id="logo"
            accept="image/*"
            onChange={handleLogoChange}
          />
          {logoPreview && (
            <img src={logoPreview} alt="Logo Preview" className="logo-preview" />
          )}
        </div>


        {/* <div className="form-group">
          <label htmlFor="bank-details">Bank Details:</label>
          <select
            id="bank-details"
            value={bankDetails.bankName}
            onChange={(e) => {
              const selectedBank = bankOptions.find((bank) => bank.bankName === e.target.value);
              setBankDetails({
                bankName: selectedBank.bankName,
                accountNumber: selectedBank.accountNumber,
                ifsc: selectedBank.ifsc,
                accountHolder: selectedBank.accountHolder
              });
            }}
            className="bank-select"
          >
            <option value="">Select Bank</option>
            {bankOptions.map((bank, index) => (
              <option key={index} value={bank.bankName}>
                {bank.bankName} - Account No: {bank.accountNumber}
              </option>
            ))}
          </select>
        </div> */}

        <div className="form-group">
          <label htmlFor="terms">Terms and Conditions:</label>
          <textarea
            id="terms"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            rows="4"
            required
          />
        </div>
        <div className="form-group">
                    <label htmlFor="signature">Authorized Signature:</label>
                    <input
                        type="file"
                        id="signature"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                setSignature(file);
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setSignaturePreview(reader.result);
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                    {signaturePreview && (
                        <img src={signaturePreview} alt="Signature Preview" className="signature-preview" />
                    )}
                </div>
    

        <div className="form-group">
          <label htmlFor="template">Select Template:</label>
          <select
            id="template"
            value={template}
            onChange={handleTemplateChange}
            className="template-select"
          >
            <option value="template1">Template 1</option>
            <option value="template2">Template 2</option>
            <option value="template3">Template 3</option>
            
          </select>
        </div>

        {/* <button type="submit" className="btn-submit">Generate Invoice and Download PDF</button>
      </form> */}
      {/* {invoices.map((invoice, index) => (
    <div key={index} id={`invoice-output-${index}`} className={templates[template].className}>
        {templates[template].header}
        <div className="body"> */}
{/* <button type="submit" className="btn-submit" disabled={loading}>
                {loading ? 'Generating...' : 'Generate Invoice and Download PDF'}
            </button>
        </form>
        {loading && <p>Loading... Please wait.</p>} 
        {invoices.map((invoice, index) => (
            <div key={index} id={`invoice-output-${index}`} className={templates[template].className}>
                {templates[template].header} */}
<button type="submit" className="btn-submit" disabled={loading}>
                {loading ? 'Generating...' : 'Generate Invoice and Download PDF'}
            </button>
        </form>
        {loading && <p>Loading... Please wait.</p>} {/* Loading indicator */}
        {invoices.map((invoice, index) => (
            <div key={index} id={`invoice-output-${index}`} className={templates[template].className}>
                {templates[template].header}


        {/* {invoices.map((invoice, index) => (
    <div key={index} id={`invoice-output-${index}`} className={templates[template].className}>
        {templates[template].header} */}
        <div className="body">
          <div className="logo-container">
            {logoPreview ? (
              <img src={logoPreview} alt="Company Logo" className="logo" />
            ) : (
              <img src={logo} alt="Company Logo" className="logo" />
            )}
          </div>
          {/* <div className="company">
            <p className="companyname"><strong>{invoice.fromCompany}</strong></p>
            <div className="agep">
              <p className="address"><span id="address">Address:</span><br></br>{invoice.fromDetails[0]}</p>
              <p className="gst"><span>GST:</span> {invoice.fromDetails[1]}</p>
              <p className="email"><span>Email:</span> {invoice.fromDetails[2]}
              <p className="phone"><span>Phone: </span>{invoice.fromDetails[3]}</p></p>
            </div>
          </div> */}
          <div className="company">
  <p className="companyname"><strong>{invoice.fromCompany}</strong></p>
  <div className="agep">
    {/* Conditionally render Address */}
    {invoice.fromDetails[0] && (
      <p className="address">
        <span id="address">Address:</span><br />
        {invoice.fromDetails[0]}
      </p>
    )}
    
    {/* Conditionally render GST */}
    {invoice.fromDetails[1] && (
      <p className="gst">
        <span>GST:</span> {invoice.fromDetails[1]}
      </p>
    )}
    
    {/* Conditionally render Email */}
    {invoice.fromDetails[2] && (
      <p className="email">
        <span>Email:</span> {invoice.fromDetails[2]}
      </p>
    )}
    
    {/* Conditionally render Phone */}
    {invoice.fromDetails[3] && (
      <p className="phone">
        <span>Phone:</span> {invoice.fromDetails[3]}
      </p>
    )}
  </div>
</div>
        
          {/* <div className="dbs">
            <div className="d">
              <h3 className="ind">Invoice details:</h3>
              <p className="invoice"><strong>Invoice No:</strong> {invoice.currentInvoiceNumber}</p>
              <p className="date"><strong>Date:</strong> {formatDate(invoice.invoiceDate)}</p>
            </div> */}
             <div className="dbs">  <br></br>
                <div className="d">
                    <h3 className="ind">Invoice details:</h3>
                    <p className="invoice"><strong>Invoice No:</strong> {(invoice.currentInvoiceNumber)}</p> {/* Display the invoice number here */}
                    <p className="date"><strong>Date:</strong> {formatDate(invoice.invoiceDate)}</p>
                </div>
            {/* <div className="b">
              <h3 className="bt" id="bt">Bill To:</h3>
              <p><strong>{invoice.toCompany}</strong></p>
              <p>{invoice.toDetails[0]}</p>
              <p>GST: {invoice.toDetails  [1]}</p>
              <p>Email: {invoice.toDetails[2]}</p>
              <p>Phone: {invoice.toDetails[3]}</p>
            </div> */}
            <div className="b">
  <h3 className="bt" id="bt">Bill To:</h3>
  <p><strong>{invoice.toCompany}</strong></p>
  <p>{invoice.toDetails[0]}</p>
  
  {/* Conditionally render GST */}
  {invoice.toDetails[1] && (
    <p>GST: {invoice.toDetails[1]}</p>
  )}
  
  {/* Conditionally render Email */}
  {invoice.toDetails[2] && (
    <p>Email: {invoice.toDetails[2]}</p>
  )}
  
  {/* Conditionally render Phone */}
  {invoice.toDetails[3] && (
    <p>Phone: {invoice.toDetails[3]}</p>
  )}
</div>
            {/* {invoice.shipToCompany && (
              <div className="s">
                <h3 className="st">Ship To:</h3>
                <p><strong>{invoice.shipToCompany}</strong></p>
                <p>{invoice.shipToDetails[0]}</p>
                <p>GST: {invoice.shipToDetails[1]}</p>
                <p>Email: {invoice.shipToDetails[2]}</p>
                <p>Phone: {invoice.shipToDetails[3]}</p>
              </div>
            )} */}
            {invoice.shipToCompany && (
  <div className="s">
    <h3 className="st">Ship To:</h3>
    <p><strong>{invoice.shipToCompany}</strong></p>
    <p>{invoice.shipToDetails[0]}</p>
    
    {/* Conditionally render GST */}
    {invoice.shipToDetails[1] && (
      <p>GST: {invoice.shipToDetails[1]}</p>
    )}
    
    {/* Conditionally render Email */}
    {invoice.shipToDetails[2] && (
      <p>Email: {invoice.shipToDetails[2]}</p>
    )}
    
    {/* Conditionally render Phone */}
    {invoice.shipToDetails[3] && (
      <p>Phone: {invoice.shipToDetails[3]}</p>
    )}
  </div>
)}
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Variety</th>
                  <th>Hsn</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.itemName}</td>
                    <td>{item.variety}</td>
                    <td>{item.hsn}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    <td>₹{item.price}</td>
                    <td>₹{formatNumberWithCommas((item.price * item.quantity).toFixed(2))}</td>
                    {/* <td>₹{(item.price * item.quantity).toFixed(2)}</td> */}

                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className="total">
                    <b>Total:</b>
                  </td>
                  <td>₹{formatNumberWithCommas(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0).toFixed(2))}</td>
              
                  {/* <td>₹{(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0).toFixed(2))}</td> */}
                </tr>
              </tbody>
            </table>
          </div>
          
        {/* <p className="atw"><span className="taiw">Total Amount in Words: </span> {numberToWordsInIndianSystem(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0))} rupees</p> */}
          <p className="atw"><span className="taiw">Total Amount in Words: </span>{numberToWordsInIndianCurrency(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0))}</p>
          <div className="bankterm"> 
            <div className="bank">
              <h3 className="bd">Bank Details:</h3>
              <p className="bdp">
                <span>Bank Name :</span> {invoice.bankDetails.bankName}  <br />
                <span>Account No: </span>{invoice.bankDetails.accountNumber}
                <br />
                <span>IFSC:</span>{invoice.bankDetails.ifsc}
                <br />
                <span>Account Holder:</span> {invoice.bankDetails.accountHolder}
              </p>
 </div>
            <div className="t">
              <h3 className="terms">Terms:</h3>
              <p className="p">  {terms}</p>
            </div>
          </div>
          {signaturePreview && (
                            <div className="signature-container">
                                {/* <h3>Authorized Signature:</h3> */}
                                <img src={signaturePreview} alt="Authorized Signature" className="signature" />
                            </div>
                        )}
          {templates[template].footer}
    </div>
       </div>
    ))}
  </div>
);

}; 

export default BulkImport;