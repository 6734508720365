import React, { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import { Link } from 'react-router-dom';
import html2canvas from "html2canvas";
import Papa from 'papaparse'; // Import PapaParse
import * as XLSX from 'xlsx'; // Import XLSX
import ".//Style/Purchase.css"; // Make sure to include your CSS file

const BulkImport = () => {
    const [letter, setletter] = useState();
  
//   const generateInvoiceNumber = () => {
//     // if (currentInvoiceNumber === null) {
//         // Load the last saved invoice number only once per session
//         // currentInvoiceNumber = generateInvoiceNumber();
//     return `${letter}-${Math.floor(Math.random() * 90000) + 10000}`;
  
// };
let lastInvoiceNumber = null; // This will hold the last generated invoice number
// You can change this to whatever prefix you want

const generateInvoiceNumber = () => {
    // If lastInvoiceNumber is null, initialize it to a random starting point
    if (lastInvoiceNumber === null) {
        lastInvoiceNumber = Math.floor(Math.random() * 9000) + 1000; // Random starting point between 1000 and 9999
    } else {
        // Increment the last invoice number by either 2 or 3
        const increment = Math.random() < 0.5 ? 2 : 3; // Randomly choose between 2 and 3
        lastInvoiceNumber += increment; // Increment by 2 or 3
    }

    return `${letter}-${lastInvoiceNumber}`;
};
const [signature, setSignature] = useState(null);
const [signaturePreview, setSignaturePreview] = useState(null);



let currentInvoiceNumber = null; // Session-based variable






const [invoices, setInvoices] = useState([]); // State to hold the list of invoices
const [pdfs, setPdfs] = useState([]); // State to store generated PDFs

const BATCH_SIZE = 50; // Adjust as needed


const [loading, setLoading] = useState(false); // State to track loading

const generateAndStoreAllInvoices = async () => {
console.log("Invoices to generate:", invoices);

if (invoices.length === 0) {
  console.error("No invoices to generate.");
  return;
}

// Generate invoice numbers and update the invoices state
const updatedInvoices = invoices.map((invoice) => {
  if (!invoice.currentInvoiceNumber) {
    invoice.currentInvoiceNumber = generateInvoiceNumber(); // Generate a new number
  }
  return invoice; // Return the updated invoice
});

// Update the state with the new invoices
setInvoices(updatedInvoices); // This triggers a re-render

// Set loading state to true
setLoading(true);

// Generate PDFs for each invoice
for (let i = 0; i < updatedInvoices.length; i++) {
  await generatePDF(updatedInvoices[i], i); // Call generatePDF for each invoice
}

// Set loading state to false after generation
setLoading(false);
};

//   console.log("Invoices to generate:", invoices);

//   if (invoices.length === 0) {
//       console.error("No invoices to generate.");
//       return;
//   }

//   // Generate invoice numbers and update the invoices state
//   const updatedInvoices = invoices.map((invoice) => {
//       if (!invoice.currentInvoiceNumber) {
//           invoice.currentInvoiceNumber = generateInvoiceNumber(); // Generate if not present
//       }
//       return invoice; // Return the updated invoice
//   });

//   // Update the state with the new invoices
//   setInvoices(updatedInvoices); // This will trigger a re-render

//   // Set loading state to true
//   setLoading(true);

//   // Generate PDFs for each invoice
//   for (let i = 0; i < updatedInvoices.length; i++) {
//       await generatePDF(updatedInvoices[i], i); // Call generatePDF for each invoice
//   }

//   // Set loading state to false after generation
//   setLoading(false);
// };

const generatePDF = async (invoice, index) => {
if (!invoice || !invoice.invoiceDate) {
  console.error(`Invalid invoice data for index: ${index}`);
  return null;
}

if (!invoice.currentInvoiceNumber) {
  invoice.currentInvoiceNumber = generateInvoiceNumber();
}

const formattedInvoiceNumber = `${invoice.currentInvoiceNumber.toString().padStart(4, "0")}`;
const invoiceDate = new Date(invoice.invoiceDate);
const formattedDate = `${invoiceDate.getFullYear()}-${(invoiceDate.getMonth() + 1).toString().padStart(2, "0")}-${invoiceDate.getDate().toString().padStart(2, "0")}`;
const sanitizedCompany = invoice.toCompany.replace(/[^a-zA-Z0-9]/g, "_");
const filename = `${sanitizedCompany}-${formattedDate}-${formattedInvoiceNumber}.pdf`;

const pdfContent = `
      Invoice Number: ${invoice.currentInvoiceNumber}
      Date: ${formattedDate}
  `;

let input = document.getElementById(`invoice-output-${index}`);

// Retry logic if the DOM element is not immediately available
for (let attempts = 5; !input && attempts > 0; attempts--) {
await new Promise((resolve) => setTimeout(resolve, 200)); // Wait 200ms
input = document.getElementById(`invoice-output-${index}`);
}

if (!input) {
console.error(`Invoice element not found for index: ${index}.`);
return null;
}

try {
const canvas = await html2canvas(input, {
  scale: 2,
  useCORS: true,
  logging: false,
});

const imgData = canvas.toDataURL("image/jpeg", 0.9);
const pdf = new jsPDF("p", "mm", "a4");

const pdfWidth = pdf.internal.pageSize.getWidth();
const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

// Save the PDF file with the formatted filename
pdf.save(filename);

// Log or update the state to confirm the file name matches the invoice number
console.log(`PDF saved with invoice number: ${formattedInvoiceNumber}`);
setPdfs((prevPdfs) => [...prevPdfs, filename]); // Add filename to the state
} catch (error) {
console.error(`Failed to generate PDF for invoice ${invoice.currentInvoiceNumber}:`, error);
return null;
}
};
const generateInvoice = async (e) => {
e.preventDefault();
await generateAndStoreAllInvoices();
};
const [showInvoiceNumbers, setShowInvoiceNumbers] = useState(false); // State to toggle invoice number visibility


const toggleInvoiceNumbers = () => {
setShowInvoiceNumbers(!showInvoiceNumbers); // Toggle the visibility
};

  // let currentInvoiceNumber = null;

  // // Retrieve the last saved invoice number from localStorage or start from 1
  // const getLastInvoiceNumber = () => {
  //   const savedNumber = localStorage.getItem("lastInvoiceNumber");
  //   return savedNumber ? parseInt(savedNumber, 10) : 0;
  // };
  
  // // Save the last invoice number to localStorage
  // const saveLastInvoiceNumber = (number) => {
  //   localStorage.setItem("lastInvoiceNumber", number);
  // };
  
  // // Generate the next invoice number, starting from the saved number
  // const generateInvoiceNumber = () => {
  //   if (currentInvoiceNumber === null) {
  //     // Load the last saved invoice number only once per session
  //     currentInvoiceNumber = getLastInvoiceNumber();
  //   }
    
  //   // Return the current invoice number and increment it for the next use
  //   const invoiceNumber = currentInvoiceNumber;
  //   currentInvoiceNumber += 1;
  
  //   // Save the new last invoice number to localStorage
  //   saveLastInvoiceNumber(currentInvoiceNumber);
  
  //   return `INV-${invoiceNumber}`;
  // };
//   let currentInvoiceNumber = null;

// // Retrieve the last saved invoice number from localStorage or start from 1
// const getLastInvoiceNumber = () => {
//   const savedNumber = localStorage.getItem("lastInvoiceNumber");
//   return savedNumber ? parseInt(savedNumber, 10) : 0; // Start from 1
// };

// // Save the last invoice number to localStorage
// const saveLastInvoiceNumber = (number) => {
//   localStorage.setItem("lastInvoiceNumber", number);
// };

// // Generate the next invoice number, starting from the saved number
// const generateInvoiceNumber = () => {
//   if (currentInvoiceNumber === null) {
//     // Load the last saved invoice number only once per session
//     currentInvoiceNumber = getLastInvoiceNumber();
//   }

//   // Format the invoice number as a 4-digit string with leading zeros
//   const invoiceNumber = String(currentInvoiceNumber).padStart(4, "0");

//   // Increment the invoice number for the next use
//   currentInvoiceNumber += 1;

//   // Save the new last invoice number to localStorage
//   saveLastInvoiceNumber(currentInvoiceNumber);

//   return `INV-${invoiceNumber}`;
// };


// const [invoices, setInvoices] = useState([]); // State to hold the list of invoices
// const [pdfs, setPdfs] = useState([]); // State to store generated PDFs

// const BATCH_SIZE = 50; // Adjust as needed

// const generateAndStoreAllInvoices = async () => {
//   console.log("Invoices to generate:", invoices);

//   if (invoices.length === 0) {
//     console.error("No invoices to generate.");
//     return;
//   }

//   for (let i = 0; i < invoices.length; i += BATCH_SIZE) {
//     const batch = invoices.slice(i, i + BATCH_SIZE);

//     for (let index = 0; index < batch.length; index++) {
//       await generatePDF(batch[index], index + i); // Queue each PDF generation sequentially
//     }
//   }
// };

// // Function to generate the PDF for each invoice
// const generatePDF = async (invoice, index) => {
//   if (!invoice || !invoice.invoiceDate) {
//     console.error(`Invalid invoice data for index: ${index}`);
//     return;
//   }

//   if (!invoice.currentInvoiceNumber) {
//     invoice.currentInvoiceNumber = generateInvoiceNumber();
//   }

//   const formattedDate = invoice.invoiceDate.toISOString().split("T")[0];
//   const sanitizedCompany = invoice.toCompany.replace(/[^a-zA-Z0-9]/g, "_");
//   const filename = `${sanitizedCompany}-${formattedDate}-${invoice.currentInvoiceNumber}.pdf`;

//   console.log(`Generating PDF with filename: ${filename}`);

//   let input = document.getElementById(`invoice-output-${index}`);

//   // Retry logic if the DOM element is not immediately available
//   for (let attempts = 5; !input && attempts > 0; attempts--) {
//     await new Promise((resolve) => setTimeout(resolve, 200)); // Wait 200ms
//     input = document.getElementById(`invoice-output-${index}`);
//   }

//   if (!input) {
//     console.error(`Invoice element not found for index: ${index}.`);
//     return null;
//   }

//   try {
//     const canvas = await html2canvas(input, {
//       scale: 2,
//       useCORS: true,
//       logging: false,
//     });

//     const imgData = canvas.toDataURL("image/jpeg", 0.9);
//     const pdf = new jsPDF("p", "mm", "a4");

//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

//     pdf.save(filename); // Download the PDF

//     setPdfs((prevPdfs) => [...prevPdfs, filename]); // Add filename to the state
//   } catch (error) {
//     console.error(`Failed to generate PDF for invoice ${invoice.currentInvoiceNumber}:`, error);
//     return null;
//   }
// };

// // Function to generate unique invoice numbers
// // const generateInvoiceNumber = () => `INV-${Math.floor(Math.random() * 1000000)}`;

// // Form submit function to trigger PDF generation
// const generateInvoice = async (e) => {
//   e.preventDefault();
//   await generateAndStoreAllInvoices();
// };




  // localStorage.clear();
//   const [invoices, setInvoices] = useState([]); // State to hold the list of invoices
//   const [pdfs, setPdfs] = useState([]); // State to store generated PDFs

//   const BATCH_SIZE = 50; // Adjust this value as needed
// // localStorage.clear();
//   const generateAndStoreAllInvoices = async () => {
//       console.log("Invoices to generate:", invoices);
  
//       if (invoices.length === 0) {
//           console.error("No invoices to generate.");
//           return;
//       }
  
//       for (let i = 0; i < invoices.length; i += BATCH_SIZE) {
//           const batch = invoices.slice(i, i + BATCH_SIZE); // Get the current batch of invoices
  
//           const pdfPromises = batch.map((invoice, index) => {
//               // Assign invoice number if not already assigned
//               if (!invoice.currentInvoiceNumber) {
//                   invoice.currentInvoiceNumber = generateInvoiceNumber();
//               }
//               console.log(`Assigned invoice number: ${invoice.currentInvoiceNumber} for index ${index + i}`);
//               return generatePDF(invoice, index + i); // Pass the invoice and index to generate PDF
//           });
  
//           try {
//               await Promise.all(pdfPromises); // Wait for all PDFs in the current batch to be generated
//           } catch (error) {
//               console.error("Error generating one or more PDFs:", error);
//           }
//       }
//   };

//   // Function to generate the PDF for each invoice
//   const generatePDF = async (invoice, index) => {
//     if (!invoice || !invoice.invoiceDate) {
//       console.error(`Invalid invoice data for index: ${index}`);
//       return;
//     }

//     if (!invoice.currentInvoiceNumber) {
//       console.error(`Invoice number is undefined for index: ${index}`);
//       return; // Stop further execution to prevent invalid filenames
//     }

//     // Use the existing invoice number and formatted date
//     const formattedDate = invoice.invoiceDate.toISOString().split('T')[0];
//     const currentInvoiceNumber = invoice.currentInvoiceNumber; // Use the preassigned number
//     const filename = `${invoice.toCompany}-${formattedDate}-${invoice.currentInvoiceNumber}.pdf`;

//     console.log(`Generating PDF with filename: ${filename}`);

//     // Locate the DOM element for the invoice
//     let input = document.getElementById(`invoice-output-${index}`);

//     // Retry logic to ensure the DOM element exists
//     let attempts = 5;
//     while (!input && attempts > 0) {
//       await new Promise((resolve) => setTimeout(resolve, 200)); // Wait 200ms
//       input = document.getElementById(`invoice-output-${index}`);
//       attempts--;
//     }

//     if (!input) {
//       console.error(`Invoice element not found for index: ${index} after multiple attempts.`);
//       return;
//     }

//     // Capture the DOM element as a canvas
//     const canvas = await html2canvas(input, {
//       scale: 2,
//       useCORS: true,
//       logging: false,
//     });

//     const imgData = canvas.toDataURL("image/jpeg", 0.7);

//     const pdf = new jsPDF("p", "mm", "a4");
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

//     // Save the PDF using the consistent filename
//     pdf.save(filename);
//   };

//   // Your form submit function or any other logic here
//   const generateInvoice = async (e) => {
//     e.preventDefault();
//     await generateAndStoreAllInvoices();
//   };








  // const [invoices, setInvoices] = useState([]); // Your invoices
  //   const [pdfs, setPdfs] = useState([]); // State to store generated PDFs

  //   const generateInvoice = async (e) => {
  //       e.preventDefault();
  //       // Assuming you have logic here to populate the invoices state
  //       await generateAndStoreAllInvoices();
  //   };

//     const generateAndStoreAllInvoices = async () => {
//       console.log("Invoices to generate:", invoices);
  
//       if (invoices.length === 0) {
//           console.error("No invoices to generate.");
//           return;
//       }
  
//       const pdfPromises = invoices.map((invoice, index) => {
//           console.log(`Generating PDF for invoice at index: ${index}`);
//           return generatePDF(invoice, index); // Pass the invoice and index
//       });
  
//       try {
//           const generatedPdfs = await Promise.all(pdfPromises);
//           setPdfs(generatedPdfs); // Store generated PDFs in state
//       } catch (error) {
//           console.error("Error generating one or more PDFs:", error);
//       }
//   };

// const generatePDF = async (invoice, index) => {
//     if (!invoice || !invoice.invoiceDate) {
//         console.error(`Invalid invoice data for index: ${index}`);
//         return;
//     }

//     const formattedDate = invoice.invoiceDate.toISOString().split('T')[0];
    
//     // Generate invoice number if not provided
//     const invoiceNumber = invoice.invoiceNumber || generateInvoiceNumber();
    
//     // Set the invoice number in the invoice object for consistency
//     invoice.invoiceNumber = invoiceNumber; // Ensure it is the same for both preview and filename

//     const filename = `${invoice.toCompany}-${formattedDate}-${invoiceNumber}.pdf`;

//     let input = document.getElementById(`invoice-output-${index}`);

//     // Retry logic: Check for the element a few times before giving up
//     let attempts = 5; // Number of attempts to wait for the element
//     while (!input && attempts > 0) {
//         await new Promise((resolve) => setTimeout(resolve, 200)); // Wait 200ms before checking again
//         input = document.getElementById(`invoice-output-${index}`);
//         attempts--;
//     }

//     if (!input) {
//         console.error(`Invoice element not found for index: ${index} after multiple attempts.`);
//         return;
//     }

//     // Capture the canvas
//     const canvas = await html2canvas(input, {
//         scale: 2, // Adjust scale for quality/size
//         useCORS: true,
//         logging: false,
//     });

//     const imgData = canvas.toDataURL("image/jpeg", 0.7); // Compress image to JPEG with 70% quality

//     const pdf = new jsPDF("p", "mm", "a4");
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    
//     // Check PDF size and adjust if necessary
//     const pdfBlob = pdf.output("blob");
//     if (pdfBlob.size > 5 * 1024 * 1024) { // If larger than 5MB
//         console.warn(`PDF size exceeds 5MB. Consider reducing image quality or dimensions.`);
//         // Implement additional logic to reduce size, e.g., adjusting quality or dimensions further
//     }

//     pdf.save(filename);
// };
 
const downloadPDF = (pdfBlob, index) => {
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `invoice-${index}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL
    };

    
    // Declare state for company details
    const [toCompanyDetails, setToCompanyDetails] = useState({
      address: "",
      gst: "",
      email: "",
      phone: ""
    });
  
    const [shipToCompanyDetails, setShipToCompanyDetails] = useState({
      address: "",
      gst: "",
      email: "",
      phone: ""
    });
    const [bulkItems, setBulkItems] = useState([]); // State to hold bulk imported items
    // const [signature, setSignature] = useState(null);
    // const [signaturePreview, setSignaturePreview] = useState(null);

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;

    if (fileType === 'text/csv') {
        // Handle CSV file
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const allInvoices = results.data.map((row) => {
                    // Basic validation
                    if (!row.invoiceDate || !row.itemNames) {
                        console.error("Missing required fields in CSV row:", row);
                        return null; // Skip this row
                    }

                    const invoiceDate = new Date(row.invoiceDate);
                    const itemNames = row.itemNames.split(',').map(item => item.trim());
                    const itemQuantities = row.itemQuantities ? row.itemQuantities.split(',').map(q => parseInt(q.trim())) : [];
                    const itemPrices = row.itemPrices ? row.itemPrices.split(',').map(p => parseFloat(p.trim())) : [];
                    const itemUnits = row.itemUnits ? row.itemUnits.split(',').map(u => u.trim()) : [];
                    const itemHsn = row.hsn ? row.hsn.split(',').map(h => h.trim()) : [];
                    const itemVariety = row.variety ? row.variety.split(',').map(v => v.trim()) : [];

                    const items = itemNames.map((name, index) => ({
                        itemName: name,
                        quantity: itemQuantities[index] || 0,
                        price: itemPrices[index] || 0,
                        unit: itemUnits[index] || "pcs",
                        hsn: itemHsn[index] || "",
                        variety: itemVariety[index] || ""
                    }));

                    return {
                        fromCompany: row.fromCompany || "",
                        fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
                        toCompany: row.toCompany || "",
                        toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
                        shipToCompany: row.shipToCompany || "",
                        shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
                        bankDetails: {
                            bankName: row.bankName || "",
                            accountNumber: row.accountNumber || "",
                            ifsc: row.ifsc || "",
                            accountHolder: row.accountHolder || ""
                        },
                        invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate,
                        items: items
                    };
                }).filter(invoice => invoice !== null); // Filter out null values

                setInvoices(allInvoices);
            },
            error: (error) => {
                console.error("Error reading CSV file:", error);
                alert("There was an error processing the CSV file. Please check the format and try again.");
            }
        });
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // Handle Excel file
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const allInvoices = jsonData.map((row) => {
                // Basic validation
                if (!row.invoiceDate || !row.itemNames) {
                    console.error("Missing required fields in Excel row:", row);
                    return null; // Skip this row
                }

                const invoiceDate = new Date(row.invoiceDate);
                const itemNames = row.itemNames.split(',').map(item => item.trim());
                const itemQuantities = row.itemQuantities ? row.itemQuantities.split(',').map(q => parseInt(q.trim())) : [];
                const itemPrices = row.itemPrices ? row.itemPrices.split(',').map(p => parseFloat(p.trim())) : [];
                const itemUnits = row.itemUnits ? row.itemUnits.split(',').map(u => u.trim()) : [];
                const itemHsn = row.hsn ? row.hsn.split(',').map(h => h.trim()) : [];
                const itemVariety = row.variety ? row.variety.split(',').map(v => v.trim()) : [];

                const items = itemNames.map((name, index) => ({
                    itemName: name,
                    quantity: itemQuantities[index] || 0,
                    price: itemPrices[index] || 0,
                    unit: itemUnits[index] || "pcs",
                    hsn: itemHsn[index] || "",
                    variety: itemVariety[index] || ""
                }));

                return {
                    fromCompany: row.fromCompany || "",
                    fromDetails: row.fromDetails ? row.fromDetails.split('|').map(detail => detail.trim()) : [],
                    toCompany: row.toCompany || "",
                    toDetails: row.toDetails ? row.toDetails.split('|').map(detail => detail.trim()) : [],
                    shipToCompany: row.shipToCompany || "",
                    shipToDetails: row.shipToDetails ? row.shipToDetails.split('|').map(detail => detail.trim()) : [],
                    bankDetails: {
                        bankName: row.bankName || "",
                        accountNumber: row.accountNumber || "",
                        ifsc: row.ifsc || "",
                        accountHolder: row.accountHolder || ""
                    },
                    invoiceDate: isNaN(invoiceDate.getTime()) ? new Date() : invoiceDate,
                    items: items
                };
            }).filter(invoice => invoice !== null); // Filter out null values

            setInvoices(allInvoices);
        };

        reader.readAsBinaryString(file);
    } else {
        alert("Unsupported file type. Please upload a CSV or Excel file.");
    }
};
 

  const [companyList, setCompanyList] = useState([]);
  // Fetch company data from the server when the component mounts
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('http://localhost:5000/company'); // Adjust the URL if necessary
        setCompanyList(response.data); // Update companyList with fetched data
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanies();
  }, []); 
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("http://localhost:5000/items"); // Adjust the URL if necessary
        setAvailableItems(response.data); // Update availableItems with fetched data
      } catch (error) {
        console.error("Error fetching items data:", error);
      }
    };

    fetchItems();
  }, []);
useEffect(() => {
    if (invoices.length > 0) {
        generatePDF(); // Call PDF generation when invoices are updated
    }
}, [invoices]);
  
  const [availableItems, setAvailableItems] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceNumber, setInvoiceNumber] = useState(generateInvoiceNumber());
  const [fromCompany, setFromCompany] = useState("");
  const [toCompany, setToCompany] = useState("");
  const [shipToCompany, setShipToCompany] = useState("");
  const [items, setItems] = useState([
    { itemName: "", quantity: "", hsn: "", price: 0, unit: "pcs" } // Added unit property
  ]);

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    ifsc: "",
    accountHolder: ""
  });
  const [terms, setTerms] = useState("Payment due within 7 days. Late payments may incur an additional fee.");
  const [totalAmount, setTotalAmount] = useState(0);
  const [fromSuggestions, setFromSuggestions] = useState([]);
  const [toSuggestions, setToSuggestions] = useState([]);
  const [shipToSuggestions, setShipToSuggestions] = useState([]);
  const [template, setTemplate] = useState("template1");
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const bankOptions = [
    {
      bankName: "Bank A",
      accountNumber: "123456789",
      ifsc: "BKID00 1234",
      accountHolder: "John Doe"
    },
    {
      bankName: "Bank B",
      accountNumber: "987654321",
      ifsc: "BKID00 5678",
      accountHolder: "Jane Doe"
    },
    {
      bankName: "Bank C",
      accountNumber: "564738291",
      ifsc: "BKID00 9012",
      accountHolder: "Bob Smith"
    }
  ];

  const templates = {
    template1: {
      className: "invoice-preview-template1",
      header: <h2 className="ti"> Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template2: {
      className: "invoice-preview-template2",
      header: <h2>Tax Invoice</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
    template3: {
      className: "invoice-preview-template3",
      // header: <h2>Bill</h2>,
      footer: <h3 className="as">Authorized signature</h3>
    },
  };
  
  const addItem = () => {
    setItems([...items, { itemName: "", quantity: "", hsn: "", price: 0, unit: "pcs" }]); // Include unit in new item
  };

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    const total = newItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalAmount(total);
  };

  const handleItemChange = (index, key, value, event) => {
    const newItems = [...items];
    
    // Update the specific field based on the key
    newItems[index][key] = value;
  
    // If the key is quantity, calculate the total without changing the price
    if (key === "quantity") {
      // Calculate the total amount for the item based on quantity and original price
      const totalPrice = newItems[index].price * value; // Use the original price
      newItems[index].totalPrice = totalPrice; // Store the total price if needed
    }
  
    setItems(newItems);
    
    // Calculate the total amount for the invoice based on all items
    const total = newItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    setTotalAmount(total);
  };
//   function numberToWordsInIndianSystem(num) {
//     if (num === 0) return "zero";

//     const belowTwenty = [
//         "","one","two","three","four","five","six","seven","eight","nine","ten","eleven","twelve","thirteen",
//         "fourteen","fifteen","sixteen","seventeen","eighteen","nineteen"
//   ];
//     const tens = [
//         "","","twenty","thirty","forty","fifty","sixty","seventy","eighty","ninety"
//     ];
//     const indianUnits = [
//         "","thousand","lakh","crore"
//     ];
//     function helper(num) {
//         if (num === 0) return "";
//         else if (num < 20) return belowTwenty[num] + " ";
//         else if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
//         else if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " hundred " + helper(num % 100);
//         else return ""; // Fallback to avoid undefined
//     }

//     let word = "";
//     let i = 0;

//     // Process the first three digits (thousands)
//     if (num % 1000 !== 0) {
//         word = helper(num % 1000) + indianUnits[i] + " ";
//     }
//     num = Math.floor(num / 1000);
//     i++;

//     // Process the remaining digits (lakhs and crores)
//     while (num > 0) {
//         if (num % 100 !== 0) {
//             word = helper(num % 100) + indianUnits[i] + " " + word;
//         }
//         num = Math.floor(num / 100);
//         i++;
//     }

//     return word.trim(); // Return the final result
// }


function formatNumberWithCommas(num) {
  // Convert the number to a string
  let numStr = num.toString();
  
  // Split the number into the integer and decimal parts
  let [integerPart, decimalPart] = numStr.split('.');
  
  // Format the integer part with commas
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  
  if (otherDigits) {
      lastThreeDigits = ',' + lastThreeDigits;
  }
  
  // Add commas for the other digits
  integerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;
  
  // Reconstruct the full number
  return decimalPart ? integerPart + '.' + decimalPart : integerPart;
}
function numberToWordsInIndianCurrency(amount) {
  // Get only the whole number part (before the decimal)
  const rupees = Math.floor(amount);

  // Convert only the rupees part to words
  const words = numberToWordsInIndianSystem(rupees) + " Rupees";

  // Format the amount with commas
  const formattedAmount = formatNumberWithCommas(rupees);

  return {
      words: words.trim(),
      formattedAmount: formattedAmount
  };
}

// // Example usage
// const result = numberToWordsInIndianCurrency(123456.78);
// console.log(result.words); // Output: "One Lakh Twenty Three Thousand Four Hundred Fifty Six Rupees"
// console.log(result.formattedAmount); // Output: "1,23,456"


function numberToWordsInIndianSystem(num) {
    if (num === 0) return "zero";

    const belowTwenty = [
      "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
      "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
  ];
  const tens = [
      "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
  ];
  const indianUnits = [
      "", "Thousand", "Lakhs", "Crore"
  ];

    function helper(num) {
        if (num === 0) return "";
        else if (num < 20) return belowTwenty[num] + " ";
        else if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
        else if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " Hundred " + helper(num % 100);
        else return ""; // Fallback to avoid undefined
    }

    let word = "";
    let i = 0;

    // Process the first three digits (thousands)
    if (num % 1000 !== 0) {
        word = helper(num % 1000) + indianUnits[i] + " ";
    }
    num = Math.floor(num / 1000);
    i++;

    // Process the remaining digits (lakhs and crores)
    while (num > 0) {
        if (num % 100 !== 0) {
            word = helper(num % 100) + indianUnits[i] + " " + word;
        }
        num = Math.floor(num / 100);
        i++;
    }

    return word.trim(); // Return the final result
}

function numberToWordsInIndianCurrency(amount) {
    // Get only the whole number part (before the decimal)
    const rupees = Math.floor(amount);

    // Convert only the rupees part to words
    const words = numberToWordsInIndianSystem(rupees) + " Rupees";

    return words.trim();
}

  const filterSuggestions = (input, setSuggestions) => {
    const filtered = companyList.filter((company) =>
      company.name.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filtered);
  };
  const [fromCompanyDetails, setFromCompanyDetails] = useState({
    address: "",
    gst: "",
    email: "",
    phone: ""
  });
  
  const handleFromCompanyChange = (e) => {
    const input = e.target.value;
    setFromCompany(input);
    filterSuggestions(input, setFromSuggestions);
  
    // Check if the input company is not in the company list
    if (!companyList.find((company) => company.name === input)) {
      // Add the new company to the company list
      setCompanyList([...companyList, {
        name: input,
        address: fromCompanyDetails.address,
        gst: fromCompanyDetails.gst,
        email: fromCompanyDetails.email,
        phone: fromCompanyDetails.phone
      }]);
    }
  };
  
  const handleFromCompanyDetailsChange = (e) => {
    const { name, value } = e.target;
    setFromCompanyDetails({ ...fromCompanyDetails, [name]: value });
  };
  
  const handleToCompanyChange = (e) => {
    const input = e.target.value;
    setToCompany(input);
    filterSuggestions(input, setToSuggestions);
  };

  const handleShipToCompanyChange = (e) => {
    const input = e.target.value;
    setShipToCompany(input);
    filterSuggestions(input, setShipToSuggestions);
  };

 
// const formatDate = (date) => {
//     if (!date || isNaN(date.getTime())) {
//         return "Invalid date"; // Return a placeholder for invalid dates
//     }
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//     return date.toLocaleDateString(undefined, options);
// };
const formatDate = (date) => {
  if (!date || isNaN(date.getTime())) {
      return "Invalid date"; // Return a placeholder for invalid dates
  }
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};


  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match("image.*")) {
      alert("Please select an image file");
      return;
    }
    if (file.size > 1024 * 1024) {
      alert("Please select an image file with a size less than 1MB");
      return;
    }
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container">
      <h1>Purchase Invoice Generator</h1>
      <form onSubmit={generateInvoice} className="form">
      <div className="form-group">
          <label htmlFor="bulk-import">Bulk Import Items:</label>
          <input
            type="file"
            id="bulk-import"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
          />
        </div>
        {/* <div className="form-group">
          <label htmlFor="invoice-date">Invoice Date:</label>
          <div className="date-inputs">
            <input
              type="number"
              id="invoice-day"
              placeholder=" DD"
              value={invoiceDate.getDate() || ""}
              onChange={(e) => {
                const day = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setDate(day);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="31"
            />
            <input
              type="number"
              id="invoice-month"
              placeholder="MM"
              value={invoiceDate.getMonth() + 1 || ""}
              onChange ={(e) => {
                const month = parseInt(e.target.value) - 1; // Months are zero-indexed
                const newDate = new Date(invoiceDate);
                newDate.setMonth(month);
                setInvoiceDate(newDate);
              }}
              required
              min="1"
              max="12"
            />
            <input
              type="number"
              id="invoice-year"
              placeholder="YYYY"
              value={invoiceDate.getFullYear() || ""}
              onChange={(e) => {
                const year = parseInt(e.target.value);
                const newDate = new Date(invoiceDate);
                newDate.setFullYear(year);
                setInvoiceDate(newDate);
              }}
              required
              min="1900 "
            />
          </div>
        </div> */}
        <div className="form-group">
          <label htmlFor="logo">Logo:</label>
          <input
            type="file"
            id="logo"
            accept="image/*"
            onChange={handleLogoChange}
          />
          {logoPreview && (
            <img src={logoPreview} alt="Logo Preview" className="logo-preview" />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="letter">Prefix :</label>
          <input
            id="letter"
            value={letter}
            onChange={(e) => setletter(e.target.value)}
            rows="4"
            required
          />
        </div>


        {/* <div className="form-group">
          <label htmlFor="bank-details">Bank Details:</label>
          <select
            id="bank-details"
            value={bankDetails.bankName}
            onChange={(e) => {
              const selectedBank = bankOptions.find((bank) => bank.bankName === e.target.value);
              setBankDetails({
                bankName: selectedBank.bankName,
                accountNumber: selectedBank.accountNumber,
                ifsc: selectedBank.ifsc,
                accountHolder: selectedBank.accountHolder
              });
            }}
            className="bank-select"
          >
            <option value="">Select Bank</option>
            {bankOptions.map((bank, index) => (
              <option key={index} value={bank.bankName}>
                {bank.bankName} - Account No: {bank.accountNumber}
              </option>
            ))}
          </select>
        </div> */}

        <div className="form-group">
          <label htmlFor="terms">Terms and Conditions:</label>
          <textarea
            id="terms"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            rows="4"
            required
          />
        </div>
        <div className="form-group">
                    <label htmlFor="signature">Authorized Signature:</label>
                    <input
                        type="file"
                        id="signature"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                setSignature(file);
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setSignaturePreview(reader.result);
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                    {signaturePreview && (
                        <img src={signaturePreview} alt="Signature Preview" className="signature-preview" />
                    )}
                </div>
    

        <div className="form-group">
          <label htmlFor="template">Select Template:</label>
          <select
            id="template"
            value={template}
            onChange={handleTemplateChange}
            className="template-select"
          >
            <option value="template1">Template 1</option>
            <option value="template2">Template 2</option>
            <option value="template3">Template 3</option>
            
          </select>
        </div>

        <button type="submit" className="btn-submit">Generate Invoice and Download PDF</button>
      </form>
      {invoices.map((invoice, index) => (
    <div key={index} id={`invoice-output-${index}`} className={templates[template].className}>
        {templates[template].header}
        <div className="body">
          <div className="logo-container">
            {logoPreview ? (
              <img src={logoPreview} alt="Company Logo" className="logo" />
            ) : (
              <img src={logo} alt="Company Logo" className="logo" />
            )}
          </div>
          {/* <div className="company">
            <p className="companyname"><strong>{invoice.fromCompany}</strong></p>
            <div className="agep">
              <p className="address"><span id="address">Address:</span><br></br>{invoice.fromDetails[0]}</p>
              <p className="gst"><span>GST:</span> {invoice.fromDetails[1]}</p>
              <p className="email"><span>Email:</span> {invoice.fromDetails[2]}
              <p className="phone"><span>Phone: </span>{invoice.fromDetails[3]}</p></p>
            </div>
          </div> */}
          <div className="company">
  <p className="companyname"><strong>{invoice.fromCompany}</strong></p>
  <div className="agep">
    {/* Conditionally render Address */}
    {invoice.fromDetails[0] && (
      <p className="address">
        <span id="address">Address:</span><br />
        {invoice.fromDetails[0]}
      </p>
    )}
    
    {/* Conditionally render GST */}
    {invoice.fromDetails[1] && (
      <p className="gst">
        <span>GST:</span> {invoice.fromDetails[1]}
      </p>
    )}
    
    {/* Conditionally render Email */}
    {invoice.fromDetails[2] && (
      <p className="email">
        <span>Email:</span> {invoice.fromDetails[2]}
      </p>
    )}
    
    {/* Conditionally render Phone */}
    {invoice.fromDetails[3] && (
      <p className="phone">
        <span>Phone:</span> {invoice.fromDetails[3]}
      </p>
    )}
  </div>
</div>
          <br></br>
          <div className="dbs">  <br></br>
                <div className="d">
                    <h3 className="ind">Invoice details:</h3>
                    <p className="invoice"><strong>Invoice No:</strong> {(invoice.currentInvoiceNumber)}</p> {/* Display the invoice number here */}
                    <p className="date"><strong>Date:</strong> {formatDate(invoice.invoiceDate)}</p>
                </div>
            {/* <div className="b">
              <h3 className="bt" id="bt">Bill To:</h3>
              <p><strong>{invoice.toCompany}</strong></p>
              <p>{invoice.toDetails[0]}</p>
              <p>GST: {invoice.toDetails  [1]}</p>
              <p>Email: {invoice.toDetails[2]}</p>
              <p>Phone: {invoice.toDetails[3]}</p>
            </div> */}
            <div className="b">
  <h3 className="bt" id="bt">Bill To:</h3>
  <p><strong>{invoice.toCompany}</strong></p>
  <p>{invoice.toDetails[0]}</p>
  
  {/* Conditionally render GST */}
  {invoice.toDetails[1] && (
    <p>GST: {invoice.toDetails[1]}</p>
  )}
  
  {/* Conditionally render Email */}
  {invoice.toDetails[2] && (
    <p>Email: {invoice.toDetails[2]}</p>
  )}
  
  {/* Conditionally render Phone */}
  {invoice.toDetails[3] && (
    <p>Phone: {invoice.toDetails[3]}</p>
  )}
</div>
            {/* {invoice.shipToCompany && (
              <div className="s">
                <h3 className="st">Ship To:</h3>
                <p><strong>{invoice.shipToCompany}</strong></p>
                <p>{invoice.shipToDetails[0]}</p>
                <p>GST: {invoice.shipToDetails[1]}</p>
                <p>Email: {invoice.shipToDetails[2]}</p>
                <p>Phone: {invoice.shipToDetails[3]}</p>
              </div>
            )} */}
            {invoice.shipToCompany && (
  <div className="s">
    <h3 className="st">Ship To:</h3>
    <p><strong>{invoice.shipToCompany}</strong></p>
    <p>{invoice.shipToDetails[0]}</p>
    
    {/* Conditionally render GST */}
    {invoice.shipToDetails[1] && (
      <p>GST: {invoice.shipToDetails[1]}</p>
    )}
    
    {/* Conditionally render Email */}
    {invoice.shipToDetails[2] && (
      <p>Email: {invoice.shipToDetails[2]}</p>
    )}
    
    {/* Conditionally render Phone */}
    {invoice.shipToDetails[3] && (
      <p>Phone: {invoice.shipToDetails[3]}</p>
    )}
  </div>
)}
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Variety</th>
                  <th>Hsn</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.itemName}</td>
                    <td>{item.variety}</td>
                    <td>{item.hsn}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unit}</td>
                    <td>₹{item.price}</td>
                    <td>₹{formatNumberWithCommas((item.price * item.quantity).toFixed(2))}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className="total">
                    <b>Total:</b>
                  </td>
                  <td>₹{formatNumberWithCommas(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0).toFixed(2))}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <p className="atw"><span className="taiw">Total Amount in Words: </span> {numberToWordsInIndianSystem(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0))} rupees</p> */}
          <p className="atw"><span className="taiw">Total Amount in Words: </span>{numberToWordsInIndianCurrency(invoice.items.reduce((acc, item) => acc + (item.price * item.quantity), 0))}</p>
          {/* <div className="bankterm"> */}
            {/* <div className="bank"> */}
              {/* <h3 className="bd">Bank Details:</h3>
              <p className="bdp">
                <span>Bank Name :</span> {invoice.bankDetails.bankName}  <br />
                <span>Account No: </span>{invoice.bankDetails.accountNumber}
                <br />
                <span>IFSC:</span>{invoice.bankDetails.ifsc}
                <br />
                <span>Account Holder:</span> {invoice.bankDetails.accountHolder}
              </p> */}
 {/* </div> */}
            <div className="t">
              <h3 className="terms">Terms:</h3>
              <p className="p">  {terms}</p>
            </div>
          </div>
          {signaturePreview && (
                            <div className="signature-container">
                                {/* <h3>Authorized Signature:</h3> */}
                                <img src={signaturePreview} alt="Authorized Signature" className="signature" />
                            </div>
                        )}
          {templates[template].footer}
    </div>
      //  </div>
    ))}
  </div>
);

}; 

export default BulkImport;