// invoiceUtils.js

// Function to generate unique invoice numbers
export const generateInvoiceNumber = () => {
    return `INV-${Math.floor(Math.random() * 1000000)}`;
};

// Function to format date
export const formatDate = (date) => {
    if (!date || isNaN(date.getTime())) {
        return "Invalid date"; // Return a placeholder for invalid dates
    }
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
};

// Function to convert number to words in Indian system
export const numberToWordsInIndianSystem = (num) => {
    if (num === 0) return "Zero";

    const belowTwenty = [
        "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
        "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    const tens = [
        "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
    const indianUnits = [
        "", "Thousand", "Lakhs", "Crore"
    ];

    function helper(num) {
        if (num === 0) return "";
        else if (num < 20) return belowTwenty[num] + " ";
        else if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
        else if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " Hundred " + helper(num % 100);
        else return ""; // Fallback to avoid undefined
    }

    let word = "";
    let i = 0;

    // Process the first three digits (thousands)
    if (num % 1000 !== 0) {
        word = helper(num % 1000) + indianUnits[i] + " ";
    }
    num = Math.floor(num / 1000);
    i++;

    // Process the remaining digits (lakhs and crores)
    while (num > 0) {
        if (num % 100 !== 0) {
            word = helper(num % 100) + indianUnits[i] + " " + word;
        }
        num = Math.floor(num / 100);
        i++;
    }

    return word.trim(); // Return the final result
};

// Function to convert number to words in Indian currency
export const numberToWordsInIndianCurrency = (amount) => {
    const rupees = Math.floor(amount);
    const words = numberToWordsInIndianSystem(rupees) + " Rupees";
    return words.trim();
};

// Function to format number with commas
export const formatNumberWithCommas = (num) => {
    let numStr = num.toString();
    let [integerPart, decimalPart] = numStr.split('.');
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);
    
    if (otherDigits) {
        lastThreeDigits = ',' + lastThreeDigits;
    }
    
    integerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;
    return decimalPart ? integerPart + '.' + decimalPart : integerPart;
};